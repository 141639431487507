import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import axios from "axios";
import { format, parseISO } from "date-fns";

// styles

// markup
const RedirectPage = () => {
  const oldZoom = [
    {
      roomId: "86755303900",
      passcode: "1111",
      id: "1",
      url: "https://us06web.zoom.us/j/86755303900?pwd=Y2Z2d2dNOWJFOGN2RVVTMXVVNXU3Zz09",
      base_url:
        "https://us06web.zoom.us/j/86755303900?pwd=Y2Z2d2dNOWJFOGN2RVVTMXVVNXU3Zz09&openExternalBrowser=1",
    },
  ];
  const [userData, setuserData] = useState({});
  useEffect(() => {
    (async () => {
      const liff = window.liff;
      await liff.init({ liffId: "1656439368-XRD2Z6AB" });
      if (!liff.isLoggedIn()) {
        liff.login({
          redirectUri: "https://dhamma-kids-zoom.pages.dev/redirect",
        });
      } else {
        let response = await liff.getProfile();
        if (Object.keys(response).length != 0) {
          //setuserData(response);

          let current_date = new Date();

          const postNewRecord = await axios({
            method: "put",
            headers: { "Content-Type": "application/json" },
            url: "https://9dxnbtvny5.execute-api.ap-southeast-1.amazonaws.com/test/items",
            data: {
              id: response.userId + ":" + Date.now(),
              userName: response.displayName,
              typeUrl: "zoom",
              lineOA: "kids",
              dateData: format(current_date, "dd-MM-yyyy"),
              timeData: format(current_date, "HH:mm"),
            },
          });

          // const result1 = await axios({
          //   method: "get",
          //   url: "https://gshoim1tph.execute-api.ap-southeast-1.amazonaws.com/test/api",
          //   //url: "https://random-room-vov26botrq-as.a.run.app/range",
          // });
          ////////////////////////
          // if (result1.status === 200) {
          //   // console.log(result1.data);
          //   // let randArr = [];
          //   // let initStart = result1.data.start - 1;
          //   // if (result1.data.mode == 1) {
          //   //   for (let i = initStart; i < result1.data.end; i++) {
          //   //     randArr.push(oldZoom[i]);
          //   //   }
          //   //   let randomRoom = randArr[Math.floor(Math.random() * randArr.length)];
          //   //   //console.log(randomRoom);
          //   // console.log(result1.data);
          //   // let roomNumber = result1.data.roomNumber;
          //   // let roomlink = newZoom[parseInt(roomNumber) - 1].base_url;
          //   // window.location.href = roomlink;

          //   // } else if (result1.data.mode == 2) {
          //   //   for (let i = initStart; i < result1.data.end; i++) {
          //   //     randArr.push(newZoom[i]);
          //   //   }
          //   //   let randomRoom = randArr[Math.floor(Math.random() * randArr.length)];
          //   //   //console.log(randomRoom);
          //   //   window.location.href = randomRoom.base_url;
          //   // }
          // } else {
          //   //window.location.href = newZoom[3 - 1].base_url;
          //   window.location.href = oldZoom.base_url
          // }
          window.location.href = oldZoom[0].base_url;
        } else {
          // let response = await liff.getProfile();
          // setuserData(response);
          alert("กรุณากดปุ่มเข้าร่วมกิจกรรมใหม่อีกครั้ง");
        }
      }

      // const result = await axios({
      //   method: "get",
      //   // url: "https://api.thedhamma.net/redirect/room/new",
      //   url: "https://api.thedhamma.net/redirect/room",
      // });
      // if (result.status === 200) {
      //   window.location.href = result.data.base_url;
      // }
    })();

    //console.log(result);
  }, []);

  return (
    <>
      <Helmet>
        <title>Zoom on App by ธรรมล้านดวง KIDS</title>

        <meta name="format-detection" content="telephone=yes"></meta>

        <meta name="title" content="Zoom on App by ธรรมล้านดวง KIDS"></meta>
        <meta name="description" content="ธรรมล้านดวง KIDS"></meta>

        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content=""></meta>
        <meta
          property="og:title"
          content="Zoom on App by ธรรมล้านดวง KIDS"
        ></meta>
        <meta property="og:description" content=""></meta>
        <meta property="og:image" content=""></meta>
      </Helmet>
      <main></main>
    </>
  );
};

export default RedirectPage;
